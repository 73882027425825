<template>
  <div class="footerVantButton">
    <wx-open-launch-app v-if="isWeixin" id="launch-btn" :appid="appId" :extinfo="extinfo" class="open-launch-app"
      @error="openError">
      <script type="text/wxtag-template">
        <style>.btn{
                 position: fixed;
  bottom: 0;
  z-index: 99999;
  left: 3%;
  margin: 0 auto;
  margin-bottom: 10px;
  width: 94%;
  text-align: center;
  height: 45px;
  line-height: 45px;
  background: red;
  border-radius: 4px;
  font-size: 18px;
  color: #f04040;
              }</style>
          <div class="btn"></div>
      </script>
    </wx-open-launch-app>

    <div class="btn_bootom_empty_occupy"></div>
    <div :class='{"button_BoxShadow" : initData.isBoxShadow}'
      class="button_bottom_default px-2.5 foot_single_btn flex-rc" v-if='initData.isSingle'>
      <van-button round block type="info" native-type="submit" v-if='initData.isFormType'>
        <div class="flex-cc">
          <span class="label_name"> {{initData.text}} </span>
        </div>
      </van-button>
      <van-button round block type="info" v-else @click="handleOperate">
        <div class="flex-cc">
          <van-icon :name="initData.icon" style="padding-right: 5px" size="1em" v-if='initData.icon' />
          <span class="label_name"> {{initData.text}} </span>
        </div>
      </van-button>
    </div>
    <div :class='{"button_BoxShadow" : initData.isBoxShadow}' class="button_bottom_default" v-if="initData.isCustom">
      <slot name="custom"></slot>
    </div>
  </div>
</template>
<script>
import linkApp from "@/utils/linkApp";
import { isWeixin, isIos } from "@/utils/common";
import { appOptions } from "@/config/settingConfig/web.js";
import { wxShare, init, is_version } from "@/utils/wxShare";
export default {
  name: "footerVantButton",
  props: {
    options: {
      type: Object,
      default: {},
    },
    path: {
      type: String,
      default: "",
    },
    id: {
      type: [String, Number],
      default: "",
    },
  },

  data() {
    return {
      appId: 'wx522c17330b3c124d', // appOptions.appId,
      isWeixin: isWeixin(),
      is_version: is_version(),
    };
  },

  computed: {
    initData() {
      let baseOptions = {
        text: "确认",
        isDisable: false,
        isFormType: false, //是否是表单类型按钮
        isSingle: true,
        icon: null,
        isBoxShadow: true,
        isCustom: false,
        btnType: "linkApp",
      };
      Object.assign(baseOptions, this.options);
      return { ...baseOptions };
    },
    extinfo() {
      return JSON.stringify({
        path: "/" + this.path,
        id: this.id,
      });
    },
  },

  methods: {
    handleOperate() {
      switch (this.initData.btnType) {
        case "linkApp":
          this.handleLinkApp();
          break;
        default:
          this.$emit("ok");
          break;
      }
    },

    handleLinkApp() {
      // let downLoadUrl = "";
      // if (isIos()) {
      //   if (isWeixin()) {
      //     // const data = {
      //     //   path: this.path,
      //     //   param: this.id,
      //     // };
      //     // linkApp(data);
      //     downLoadUrl =
      //       "http://mp.weixin.qq.com/mp/redirect?url=" + appOptions.appStoreUrl;
      //     window.location.href = downLoadUrl;
      //   } else {
      //     downLoadUrl = appOptions.appStoreUrl;
      //     window.location.href = downLoadUrl;
      //   }
      // } else {
      //   downLoadUrl = appOptions.downLoadUrl;
      //   window.location.href = downLoadUrl;
      // }

      const data = {
        // path: this.path,
        // param: this.id,
      };
      linkApp(data);
      // console.log("linkApp", downLoadUrl);
    },
    openError() {
      this.$router.push({ name: "index" });
    },
  },
};
</script>

     <style>
.btn {
  position: fixed;
  bottom: 0;
  left: 3%;
  z-index: 9999;
  margin: 0 auto;
  margin-bottom: 10px;
  width: 94%;
  text-align: center;
  height: 45px;
  line-height: 45px;
  background: red;
  border-radius: 4px;
  font-size: 18px;
  color: #f04040;
  /* position: relative; */
}
</style>

