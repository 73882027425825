<template>
  <div class="vantOverlay">
    <van-overlay :show="isShowOverlay" @click="handleClick">
      <div class="wrapper" @click.stop>
        <slot name='innerBox'></slot>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    // options: {
    //   type: Object,
    //   default: {
    //       isControl: false
    //   },
    // },
    isControl: {
 type: Boolean,
      default: false,
    },
    showOverlay: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
    //   showOverlay: false,
    };
  },

  computed: {
      isShowOverlay:{
          get(){
              if(this.showOverlay){
                  return this.showOverlay
              }
              return false
          }
      }
  },
  mounted() {},

  methods: {
    handleClick() {
      const isControl  = this.isControl;
      if (isControl) {
        this.$emit("clickOver");
      } else {
        this.showOverlay = true;
      }
    },
    initOverlay(record) {
      if (record) {
        this.showOverlay = record;
      } else {
        this.showOverlay = true;
      }
    },
  },

  watch: {},
};
</script>


